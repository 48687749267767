<template>
  <div class="Awarp">
    <dashCard
      class="dieselgeneratorBox1"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">发电机运行信息</template>
      <template slot="aside">
        <a-select
          v-model="searchObj['ITEM']"
          style="width: 256px"
          @change="handleChange"
          :getPopupContainer="triggerNode => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in itemList"
            :key="key"
            :value="item.assetNumber"
          >
            {{ item.machine_name }}
          </a-select-option>
        </a-select>
      </template>
      <div class="card-content1">
        <div
          class="product-pre"
          style="margin-right: 80px; margin-left: 40px; position: relative"
        >
          <div>
            <img src="@/assets/imgs/add/product3.png" />
            <div
              style="
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 30px;
                width: 322px;
              "
            >
              <div
                style="
                  color: #686868;
                  font-size: 14px;
                  font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                  font-weight: 400;
                "
              >
                <div>累计运行时间</div>
                <div style="margin-top: 4px">
                  <span
                    style="
                      font-size: 20px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Medium;
                      font-weight: 600;
                      color: #000000;
                    "
                    >{{newobj.运行小时数}}</span
                  >
                  <span
                    style="
                      font-size: 12px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: rgba(0, 0, 0, 0.45);
                      margin-left: 4px;
                    "
                    >h</span
                  >
                </div>
              </div>
              <div
                style="
                  color: #686868;
                  font-size: 14px;
                  font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                  font-weight: 400;
                "
              >
                <div>累计发电量</div>
                <div style="margin-top: 4px">
                  <span
                    style="
                      font-size: 20px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Medium;
                      font-weight: 600;
                      color: #000000;
                    "
                    >{{newobj.累计发电量}}</span
                  >
                  <span
                    style="
                      font-size: 12px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: rgba(0, 0, 0, 0.45);
                      margin-left: 4px;
                    "
                    >kWh</span
                  >
                </div>
              </div>
              <div
                style="
                  color: #686868;
                  font-size: 14px;
                  font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                  font-weight: 400;
                "
              >
                <div>累计油耗</div>
                <div style="margin-top: 4px">
                  <span
                    style="
                      font-size: 20px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Medium;
                      font-weight: 600;
                      color: #000000;
                    "
                    >{{newobj.总计燃油消耗量}}</span
                  >
                  <span
                    style="
                      font-size: 12px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: rgba(0, 0, 0, 0.45);
                      margin-left: 4px;
                    "
                    >L</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product-info">
          <div class="product-status">
            <div>工作状态</div>
            <div>
              <font>{{newobj.工作状态}}</font>
            </div>
          </div>
          <div class="product-info-item">
            <div class="product-info-item-top">
              <span>转速</span>
              <span>{{newobj.转速.num}}转</span>
            </div>
            <div class="product-info-item-progress">
              <span
                :style="{ width: (newobj.转速.num / newobj.转速.count).toFixed(2) * 100 + '%' }"
              ></span>
            </div>
            <div class="product-info-item-bottom">
              <span>0</span>
              <span>{{newobj.转速.count}}转</span>
            </div>
          </div>

          <div class="product-info-item">
            <div class="product-info-item-top">
              <span>频率</span>
              <span>{{newobj.频率.num}}HZ</span>
            </div>
            <div class="product-info-item-progress">
              <span
                :style="{ width: (newobj.频率.num / newobj.频率.count).toFixed(2) * 100 + '%' }"
              ></span>
            </div>
            <div class="product-info-item-bottom">
              <span>0</span>
              <span>{{newobj.频率.count}}HZ</span>
            </div>
          </div>

          <div class="product-info-item">
            <div class="product-info-item-top">
              <span>有功功率</span>
              <span>{{newobj.有功功率.num}}KW</span>
            </div>
            <div class="product-info-item-progress">
              <span
                :style="{ width: (newobj.有功功率.num / newobj.有功功率.count).toFixed(2) * 100 + '%' }"
              ></span>
            </div>
            <div class="product-info-item-bottom">
              <span>0</span>
              <span>{{newobj.有功功率.count}}KW</span>
            </div>
          </div>
          <div class="product-info-item">
            <div class="product-info-item-top">
              <span>无功功率</span>
              <span>{{newobj.总无功功率.num}}kVar</span>
            </div>
            <div class="product-info-item-progress">
              <span
                :style="{ width: (newobj.总无功功率.num / newobj.总无功功率.count).toFixed(2) * 100 + '%' }"
              ></span>
            </div>
            <div class="product-info-item-bottom">
              <span>0</span>
              <span>{{newobj.总无功功率.count}}kVar</span>
            </div>
          </div>
        </div>
      </div>
      <a-row :gutter="16" type="flex" justify="space-between" align="bottom">
        <a-col :span="11">
          <div class="card-content1" style="flex-direction: column">
            <div class="product-info">
              <div class="product-info-item">
                <div class="product-info-item-top">
                  <span>滑油温度</span>
                  <span>{{newobj.总无功功率.num}}°C</span>
                </div>
                <div class="product-info-item-progress">
                  <span
                    :style="{ width: (newobj.总无功功率.num / newobj.总无功功率.count).toFixed(2) * 100 + '%' }"
                  ></span>
                </div>
                <div class="product-info-item-bottom">
                  <span>0</span>
                  <span>{{newobj.总无功功率.count}}°C</span>
                </div>
              </div>
            </div>
            <div class="product-info">
              <div class="product-info-item">
                <div class="product-info-item-top">
                  <span>冷却液温度</span>
                  <span>{{newobj.冷却液温度.num}}°C</span>
                </div>
                <div class="product-info-item-progress">
                  <span
                    :style="{ width: (newobj.冷却液温度.num / newobj.冷却液温度.count).toFixed(2) * 100 + '%' }"
                  ></span>
                </div>
                <div class="product-info-item-bottom">
                  <span>0</span>
                  <span>{{newobj.冷却液温度.count}}°C</span>
                </div>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="11">
          <div class="card-content1" style="flex-direction: column">
            <div class="product-info">
              <div class="product-info-item">
                <div class="product-info-item-top">
                  <span>滑油压力</span>
                  <span>{{newobj.滑油压力.num}}kPa</span>
                </div>
                <div class="product-info-item-progress">
                  <span
                    :style="{ width: (newobj.滑油压力.num / newobj.滑油压力.count).toFixed(2) * 100 + '%' }"
                  ></span>
                </div>
                <div class="product-info-item-bottom">
                  <span>0</span>
                  <span>{{newobj.滑油压力.count}}kPa</span>
                </div>
              </div>
            </div>
            <div class="product-info">
              <div class="product-info-item">
                <div class="product-info-item-top">
                  <span>电池电压</span>
                  <span>{{newobj.电池电压.num}}V</span>
                </div>
                <div class="product-info-item-progress">
                  <span
                    :style="{ width: (newobj.电池电压.num / newobj.电池电压.count).toFixed(2) * 100 + '%' }"
                  ></span>
                </div>
                <div class="product-info-item-bottom">
                  <span>0</span>
                  <span>{{newobj.电池电压.count}}V</span>
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row
        :gutter="16"
        type="flex"
        justify="space-between"
        align="bottom"
        style="margin-top: 24px"
      >
        <a-col :span="11">
          <span style="margin-bottom: 16px; display: inline-block">电压</span>
          <div class="card-content1" style="flex-direction: column">
            <div class="product-info">
              <div class="product-info-item" style="margin-bottom: 10px">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      font-size: 14px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: #1f1f1f;
                    "
                  >
                    A相(V)
                  </div>
                  <div class="product-info-item-progress1">
                    <span
                      :style="{ width: (newobj.相电压A.num / newobj.相电压A.count).toFixed(2) * 100 + '%' }"
                    ></span>
                  </div>
                  <div
                    style="
                      font-size: 14px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: #1f1f1f;
                    "
                  >
                    {{newobj.相电压A.num}}
                  </div>
                </div>
              </div>
            </div>
            <div class="product-info">
              <div class="product-info-item" style="margin-bottom: 10px">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      font-size: 14px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: #1f1f1f;
                    "
                  >
                    B相(V)
                  </div>
                  <div class="product-info-item-progress1">
                    <span
                      :style="{ width: (newobj.相电压B.num / newobj.相电压B.count).toFixed(2) * 100 + '%' }"
                    ></span>
                  </div>
                  <div
                    style="
                      font-size: 14px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: #1f1f1f;
                    "
                  >
                    {{newobj.相电压B.num}}
                  </div>
                </div>
              </div>
            </div>
            <div class="product-info">
              <div class="product-info-item" style="margin-bottom: 10px">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      font-size: 14px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: #1f1f1f;
                    "
                  >
                    C相(V)
                  </div>
                  <div class="product-info-item-progress1">
                    <span
                      :style="{ width: (newobj.相电压C.num / newobj.相电压C.count).toFixed(2) * 100 + '%' }"
                    ></span>
                  </div>
                  <div
                    style="
                      font-size: 14px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: #1f1f1f;
                    "
                  >
                    {{newobj.相电压C.num}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="11">
          <span style="margin-bottom: 16px; display: inline-block">电流</span>
          <div class="card-content1" style="flex-direction: column">
            <div class="product-info">
              <div class="product-info-item" style="margin-bottom: 10px">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      font-size: 14px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: #1f1f1f;
                    "
                  >
                    A相(A)
                  </div>
                  <div class="product-info-item-progress1">
                    <span
                      :style="{ width: (newobj.相电流A.num / newobj.相电流A.count).toFixed(2) * 100 + '%' }"
                    ></span>
                  </div>
                  <div
                    style="
                      font-size: 14px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: #1f1f1f;
                    "
                  >
                    {{newobj.相电流A.num}}
                  </div>
                </div>
              </div>
            </div>
            <div class="product-info">
              <div class="product-info-item" style="margin-bottom: 10px">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      font-size: 14px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: #1f1f1f;
                    "
                  >
                    B相(A)
                  </div>
                  <div class="product-info-item-progress1">
                    <span
                      :style="{ width: (newobj.相电流B.num / newobj.相电流B.count).toFixed(2) * 100 + '%' }"
                    ></span>
                  </div>
                  <div
                    style="
                      font-size: 14px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: #1f1f1f;
                    "
                  >
                    {{newobj.相电流B.num}}
                  </div>
                </div>
              </div>
            </div>
            <div class="product-info">
              <div class="product-info-item" style="margin-bottom: 10px">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      font-size: 14px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: #1f1f1f;
                    "
                  >
                    C相(A)
                  </div>
                  <div class="product-info-item-progress1">
                    <span
                      :style="{ width: (newobj.相电流C.num / newobj.相电流C.count).toFixed(2) * 100 + '%' }"
                    ></span>
                  </div>
                  <div
                    style="
                      font-size: 14px;
                      font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
                      font-weight: 400;
                      color: #1f1f1f;
                    "
                  >
                    {{newobj.相电流C.num}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <dieselgeneratorBox1
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
    <script>
import Img003 from "@/assets/imgs/add/product3.png";
import Img004 from "@/assets/imgs/add/product4.png";
export default {
  name: "dieselgeneratorBox1",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      assetNumber: "",
      itemList: [],
      searchObj: {
        ITEM: "", //选中的项目
      },
      newobj: {
        工作状态: "",
        累计发电量:'',
        总计燃油消耗量:"",
        运行小时数:"",
        相电流A: {
          num: "",
          count: "",
        },
        相电流B: {
          num: "",
          count: "",
        },
        相电流C: {
          num: "",
          count: "",
        },
        相电压A: {
          num: "",
          count: "",
        },
        相电压B: {
          num: "",
          count: "",
        },
        相电压C: {
          num: "",
          count: "",
        },
        滑油温度: {
          num: "",
          count: "",
        },
        滑油压力: {
          num: "",
          count: "",
        },
        冷却液温度: {
          num: "",
          count: "",
        },
        电池电压: {
          num: "",
          count: "",
        },
        转速: {
          num: "",
          count: "",
        },
        状态位: {
          num: "",
          count: "",
        },
        频率: {
          num: "",
          count: "",
        },
        有功功率: {
          num: "",
          count: "",
        },
        总无功功率: {
          num: "",
          count: "",
        },
      },
      detailInfo: [],
    };
  },
  computed: {
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 切换项目
    handleChange(value) {
      this.assetNumber = value;
      this.getData();
    },
    getList() {
      let data = {
        Dept: this.currentSelectDeptInfo.CODE,
      };
      let url = Config.dev_url + "/api-apps/charger/assetList";
      this.$axios.post(url, data).then((res) => {
        this.itemList = res.data.data;
      });
    },
    // 获取数据
    getData() {
      this.isLoading = true;
      let url = Config.dev_url + "/api-apps/genUtilization/operatingParameter";
      let data = {
        assetNumber: this.assetNumber,
      };
      this.$axios.post(url, data).then((res) => {
        this.detailInfo = res.data.data;
        // console.log(res);
        this.detailInfo.forEach((obj) => {
          if (obj.name == "工作状态") {
              this.newobj.工作状态 = obj.num
          }else if(obj.name == "累计发电量"){
              this.newobj.累计发电量 = obj.num
          }else if(obj.name == "总计燃油消耗量"){
              this.newobj.总计燃油消耗量 = obj.num
          }else if(obj.name == "运行小时数"){
              this.newobj.运行小时数 = obj.num
          }else if(obj.name == "相电流A"){
              this.newobj.相电流A = obj
          }else if(obj.name == "相电流B"){
              this.newobj.相电流B = obj
          }else if(obj.name == "相电流C"){
              this.newobj.相电流C = obj
          }else if(obj.name == "相电压A"){
              this.newobj.相电压A = obj
          }else if(obj.name == "相电压B"){
              this.newobj.相电压B = obj
          }else if(obj.name == "相电压C"){
              this.newobj.相电压C = obj
          }else if(obj.name == "滑油温度"){
              this.newobj.滑油温度 = obj
          }else if(obj.name == "滑油压力"){
              this.newobj.滑油压力 = obj
          }else if(obj.name == "冷却液温度"){
              this.newobj.冷却液温度 = obj
          }else if(obj.name == "电池电压"){
              this.newobj.电池电压 = obj
          }else if(obj.name == "转速"){
              this.newobj.转速 = obj
          }else if(obj.name == "状态位"){
              this.newobj.状态位 = obj
          }else if(obj.name == "频率"){
              this.newobj.频率 = obj
          }else if(obj.name == "有功功率"){
              this.newobj.有功功率 = obj
          }else if(obj.name == "总无功功率"){
              this.newobj.总无功功率 = obj
          }
          
        });
      });
      
      this.isLoading = false;
    },
  },
  mounted() {
    this.getList();
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.dieselgeneratorBox1 {
  .card-content1 {
    display: flex;
    .product-pre {
      width: 322px;
      margin-right: 23px;
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: auto;
      }
    }
    .product-info {
      flex: 1;
      .product-status {
        div {
          &:nth-child(1) {
            font-size: 16px;
            font-weight: 400;
            color: #595959;
            line-height: 24px;
          }
          &:nth-child(2) {
            margin-top: 6px;
            margin-bottom: 16px;

            font-size: 36px;
            font-weight: 500;
            color: #51a34b;
            line-height: 48px;
          }
        }
      }
      .product-info-item {
        margin-bottom: 24px;
        .product-info-item-top {
          display: flex;
          justify-content: space-between;

          font-size: 16px;
          font-weight: 500;
          color: #1f1f1f;
          line-height: 20px;
        }
        .product-info-item-progress {
          width: 100%;
          height: 8px;
          background: #dbdbdb;
          margin-top: 14px;
          margin-bottom: 9px;
          span {
            display: block;
            height: inherit;
            width: 0%;
            background: #51a34b;
          }
        }
        .product-info-item-progress1 {
          width: 70%;
          height: 8px;
          background: #dbdbdb;
          margin-top: 14px;
          margin-bottom: 9px;
          span {
            display: block;
            height: inherit;
            width: 0%;
            background: #51a34b;
          }
        }
        .product-info-item-bottom {
          display: flex;
          justify-content: space-between;

          font-size: 14px;
          font-weight: 400;
          color: #696969;
          line-height: 16px;
        }
      }
    }
  }
  .product-pre1 {
    margin-top: 40px;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }
  /deep/.ant-descriptions-bordered {
    .ant-descriptions-view {
      border-radius: 0;
    }
    .ant-descriptions-item-label {
      background: transparent;
      // width: 362px;
    }
    .ant-descriptions-item-content {
      background: #f5f5f5;
      display: flex;
      justify-content: space-between;
      padding: 10px 16px;

      font-size: 14px;
      font-weight: 400;
      color: #1f1f1f;
      line-height: 20px;
    }
    .ant-descriptions-item-colon {
      padding: 10px 16px;

      font-size: 14px;
      font-weight: 400;
      color: #1f1f1f;
      line-height: 20px;
    }
  }
}
</style>
